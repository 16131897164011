exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-beginners-js": () => import("./../../../src/pages/beginners.js" /* webpackChunkName: "component---src-pages-beginners-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-donation-success-js": () => import("./../../../src/pages/donation-success.js" /* webpackChunkName: "component---src-pages-donation-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-success-js": () => import("./../../../src/pages/order-success.js" /* webpackChunkName: "component---src-pages-order-success-js" */),
  "component---src-pages-privacyverklaring-js": () => import("./../../../src/pages/privacyverklaring.js" /* webpackChunkName: "component---src-pages-privacyverklaring-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tips-tricks-js": () => import("./../../../src/pages/tips-tricks.js" /* webpackChunkName: "component---src-pages-tips-tricks-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-download-file-js": () => import("./../../../src/templates/download-file.js" /* webpackChunkName: "component---src-templates-download-file-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

